// Quasar Sass (& SCSS) Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the Sass/SCSS variables found in Quasar's source Sass/SCSS files.

// Check documentation for full list of Quasar variables

// Your own variables (that are declared here) and Quasar's own
// ones will be available out of the box in your .vue/.scss/.sass files

// It's highly recommended to change the default colors
// to match your app's branding.
// Tip: Use the "Theme Builder" on Quasar's documentation website.

$primary: #027BE3
$secondary: #26A69A
$accent: #9C27B0

$positive: #21BA45
$negative: #C10015
$info: #31CCEC
$warning: #F2C037

$color-stats: #c5e2a5
$color-antenne: #92CE9E
$color-candidature: #90CCC6
$color-enquete: #9C27B0
$color-stagiaire: #D396BE
$color-pedagogie: #E09F9F
$color-entreprise: #E8C6A4
$color-postformation: #989DD6
$color-taxes: #6AA6DA
$color-facturation: #b898d6
$color-apiservice: #d5d698
$color-black: #111111
$color-white: #FEFEFE
$color-profil: #2B303A
