@import '~src/css/quasar.variables.sass', 'quasar/src/css/variables.sass'
/* GLOBAL */
.q-checkbox__label
  padding-top: 4px

body.desktop .q-hoverable:hover .q-focus-helper
  background: inherit
  opacity: 0

a
  text-decoration: none !important
  color: inherit

  *
    text-decoration: none !important

.bold
  font-weight: bold

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0

input[type=number]
  -moz-appearance: textfield

.q-field [tabindex]
  outline: 0

.background-entreprise
  background-color: $color-entreprise
  color: $color-white

.background-antenne
  background-color: $color-antenne
  color: $color-white

.background-profil
  background-color: $color-profil
  color: $color-white

.background-candidature
  background-color: $color-candidature
  color: $color-white

.background-stagiaire
  background-color: $color-stagiaire
  color: $color-white

.background-pedagogie
  background-color: $color-pedagogie
  color: $color-white

.background-postformation
  background-color: $color-postformation
  color: $color-white

.background-profile
  background-color: $color-white
  color: $color-black

.bg-profil
  background-color: $color-profil

.bg-stagiaire
  background-color: $color-stagiaire

.background-taxes
  background-color: $color-taxes
  color: $color-white

.background-facturation
  background-color: $color-facturation
  color: $color-white

  &.q-toolbar
    border-bottom: 1px solid #CCC

/* some grid stuff */

.col-padding-15
  padding: 15px

.col-padding-20-15
  padding: 20px 15px

.col-padding-0-10
  padding: 0 10px !important

.col-padding-0-20
  padding: 0 20px

.col-padding-0-30
  padding: 0 30px

.col-padding-10-0
  padding: 10px 0

.col-padding-15-0
  padding: 15px 0px !important

.col-padding-5-0
  padding: 5px 0

.col-padding-0-20-20
  padding: 0 0 20px

.col-padding-20-0-10
  padding: 20px 0 10px

.col-padding-20
  padding: 20px

.col-padding-10
  padding: 10px

.col-padding-20-40
  padding: 20px 40px

.col-padding-10-20
  padding: 10px 20px

.col-padding-10-30
  padding: 10px 30px

.col-padding-10-0-10-50
  padding: 10px 0 10px 50px

.col-padding-0-0-0-30
  padding: 0 0 0 30px

.col-padding-10-30-20
  padding: 10px 30px 20px

.col-padding-15-30-15
  padding: 15px 30px 15px

.col-padding-0-40-20
  padding: 0 40px 20px

.col-padding-0-40-20
  padding: 0 40px 20px

.col-padding-0-30-20
  padding: 0 30px 20px

.col-padding-40-20-0
  padding: 40px 20px 0

.col-padding-40-10-0
  padding: 40px 10px 0

.col-padding-30-10-0
  padding: 30px 10px 0

.col-padding-0-0-0-15
  padding: 0 0 0 15px !important

.mt-10
  margin-top: 10px

.mt-20
  margin-top: 20px

.mb-20
  margin-bottom: 20px

.max-width-180
  width: 180px
  min-width: 180px

.q-panel .q-tab-panel
  padding: 25px

.col-nopadding
  padding: 0 !important

.col-nopadding-top
  padding-top: 0 !important

.col-nopadding-bottom
  padding-bottom: 0 !important

.col-nopadding-right
  padding-right: 0 !important

.col-nopadding-left
  padding-left: 0 !important

.q-dialog
  &.fullscreen .q-card
    width: 100% !important
    max-width: 1150px !important
    margin: auto 10%

    .q-tab-panels
      padding: 0

    .q-card__section
      padding: 20px

      .text-h5
        padding: 0 20px
        margin-left: 0

    .q-tab-panel
      padding: 0

    .q-dialog__inner--minimized > div
      max-width: auto

    .q-field--with-bottom
      padding-bottom: 0

    .q-field--outlined.q-field--readonly
      .q-field__control:before
        border-style: solid

  .q-tab-panel
    padding: 20px

  .modal-button-container
    button:last-child
      margin-left: 30px

  .q-uploader
    height: 100%
    width: 100%

.specific-dropdown
  padding: 0
  margin: 0

  &.padding-inset-0-12
    padding: 0 12px

  .q-btn__wrapper
    padding: 0
    margin: 0

    i
      padding: 0
      margin: 0
      width: 10px

.q-toolbar
  &.toolbar-with-border-bottom
    border-bottom: 1px solid #CCC

  .button-container-main-toolbar button
    height: 50px
    border-radius: 0
    float: right
    margin-right: -15px

.toolbar-reduced-height
  height: calc(100% - 50px) !important

.q-card
  width: 100%

  .q-uploader
    height: 100%
    width: 100%

  .q-toolbar
    height: 20px

  .doc-card-title
    margin: 15px 0 15px -24px
    padding: 2px 10px 2px 24px
    position: relative
    border-radius: 3px 5px 5px 0

    &:after
      content: ""
      position: absolute
      top: 100%
      left: 0
      width: 0
      height: 0
      border: 0 solid transparent
      border-top-color: rgb(100, 100, 100)
      border-width: 9px 0 0 11px

.q-btn-group--rounded
  box-shadow: none

  > div:first-child
    button.q-btn--rounded
      border-radius: 14px 0 0 14px

  > div:last-child
    button.q-btn--rounded
      border-radius: 0 14px 14px 0

/* TABLE */
.q-table
  border-top: 1px solid #DDD

  th
    font-size: 15px
    font-weight: bold

  tbody td
    font-size: 14px

  thead tr.no-whitespace th,
  tbody tr.no-whitespace td
    white-space: normal

  thead th img
    width: 15px
    height: 15px
    vertical-align: middle
    margin-top: -5px

  tbody td button,
  tfoot td button
    margin-top: -3px

  td.border-right-none,
  th.border-right-none
    border-right: none !important
    width: 5px
    padding: 0

  td.border-left-none,
  th.border-left-none
    border-left: none !important
    padding-left: 0

  tfoot td
    font-weight: bold

.no-bordertop
  table
    border-top: 0
/* LIST */
.q-list.q-list--separator
  &.border-top
    border-top: 1px solid #DDD

  .q-item.noborderseparator
    border-top: none

/* BUTTON */
button
  i.q-icon
    margin: -4px 4px 0 2px

/* HOMEPAGE */
.change-background
  transition: background-image 1s

  &:before
    content: " "
    position: absolute
    top: 0
    bottom: 0
    left: 0
    right: 0
    background-color: rgba(0, 0, 0, 0.5)
    z-index: 0

  &.change-background-unlog a
    color: $color-black
    position: absolute
    bottom: -50px
    left: -20px

    &:visited
      color: $color-black

/* HEADER */
header
  .logo img
    max-width: 40px
    width: 50vh

  button
    img
      max-width: 20px
      margin-right: 10px
      margin-top: -5px

  a img
      max-width: 20px
      margin-right: 10px
      margin-top: -7px

/* CHART */
.pie-chart-container
  padding: 15px 0

/* LEFT MENU */
.q-splitter__before
  padding-bottom: 10px

  .q-toolbar__title
    text-align: center
    text-transform: uppercase

  .search-input
    margin: 5px 15px 25px

  // ! Je le commente car dans FormStagiaire, il y a un problème de style avec les tabs à gauche (ca les déplace de 10px vers la droite quand la souris est dessus)
  .q-tabs
    .q-tab
      padding: 15px
      text-align: left

      .q-tab__content,
      .q-tab__label
        width: 100%
        transition: all .2s

        img,
        .q-badge
          width: 20px
          height: 20px
          margin-right: 10px
          float: left

        .q-badge
          border-radius: 50%

      &[aria-selected="true"],
      &:hover
        background: $color-white

        .q-tab__label
          padding-left: 30px
          transition: all .2s

  ul.left-menu-list
    list-style-type: none
    padding: 0
    margin: 0

    a
      width: 100%

    li
      padding: 19px 13px
      text-align: left
      cursor: pointer

      .q-tab__label
        width: 100%
        transition: all .2s

        img
          width: 20px
          height: 20px
          margin-right: 10px
          float: left

      &.selected,
      &:hover
        background: $color-white

        .q-tab__label
          padding-left: 30px
          transition: all .2s

  &.template-antenne
    li.selected
      border-left: 2px solid $color-antenne

    .q-tab__indicator
      color: $color-antenne

  &.template-entreprise
    li.selected
      border-left: 2px solid $color-entreprise

    .q-tab__indicator
      color: $color-entreprise

  &.template-candidature
    li.selected
      border-left: 2px solid $color-candidature

    .q-tab__indicator
      color: $color-candidature

  &.template-stagiaire
    li.selected
      border-left: 2px solid $color-stagiaire

    .q-tab__indicator
      color: $color-stagiaire

  &.template-pedagogie
    li.selected
      border-left: 2px solid $color-pedagogie

    .q-tab__indicator
      color: $color-pedagogie

  &.template-postformation
    li.selected
      border-left: 2px solid $color-postformation

    .q-tab__indicator
      color: $color-postformation

  &.template-taxes
    li.selected
      border-left: 2px solid $color-taxes

    .q-tab__indicator
      color: $color-taxes

  &.template-facturation
    li.selected
      border-left: 2px solid $color-taxes

    .q-tab__indicator
      color: $color-facturation

  &.template-profil
    li.selected
      border-left: 2px solid $color-profil

    .q-tab__indicator
      color: $color-profil

.menu-container
  display: flex
  flex-direction: column
  text-align: center
  color: #333
  height: calc(100vh - 50px)
  justify-content: flex-start

  .menu-container-close
    position: absolute
    right: 10px
    top: 10px
    cursor: pointer

    i
      font-size: 20px

  .menu-container-icon
    margin-top: 80px

    i
      font-size: 80px

  .menu-container-name
    font-size: 25px

  .menu-container-logout
    margin-top: auto
    margin-bottom: 10px

  .menu-container-menu
    ul
      list-style-type: none
      padding: 0
      margin: 0
      color: $color-black

      li
        padding: 25px 10px
        text-align: center
        position: relative
        font-size: 16px
        color: $color-black
        text-decoration: none
        cursor: pointer

        &.active
          color: $color-black !important

        &:before
          content: " "
          position: absolute
          left: 0
          bottom: 0
          height: 1px
          width: 100px
          left: 50%
          margin-left: -50px
          border-bottom: 1px solid #CCC

        &:last-child:before
          border: none

        i
          margin-right: 10px
          font-size: 20px

        a
          color: $color-black

.q-splitter__after
  overflow-x: hidden

/* DRAWER left filter */
.drawer-filter
  .filters-list-drawer
    padding: 70px 15px 10px
    height: 100vh
    overflow: auto

    .q-toolbar__title
      margin-bottom: 20px

    .close
      position: absolute
      right: 10px
      top: 60px
      cursor: pointer
      margin-right: 10px

      i
        font-size: 20px

    ul
      list-style-type: none
      padding: 0
      margin: 0
      box-sizing: border-box

      label.q-field__label
        top: -5px
        position: relative

    .q-btn-group
      margin: auto

    .q-field--outlined.q-field--readonly
      .q-field__control:before
        border-style: solid

    .q-field--outlined.q-field--readonly
      margin-bottom: 10px

.table-calendar
  .q-badge
    width: 20px
    height: 20px
    margin-right: 10px
    float: left
    border-radius: 50%

/* TEMPLATE */
#empty-template
  display: flex
  align-items: center
  justify-content: center
  margin: 100px auto

/* LIST RESSOURCES INDENTED */
.item.level-0
  padding: 20px 0 20px 20px

/* ASTERISK RED */
.required .q-field__label:after
  content: ' *'
  color: #f00

.capitalize-first
  text-transform: capitalize
